.css-11bfvty-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions {
    margin-left: 10px !important;
}

.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input {
    padding-right: 16px !important;
}

.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
    margin-left: 0px !important;
    margin-right: 6px !important;
}

.css-16c50h-MuiInputBase-root-MuiTablePagination-select .MuiTablePagination-select {
    padding-left: 4px !important;
}


.css-11bfvty-MuiToolbar-root-MuiTablePagination-toolbar {
    padding-left: 0px !important;
}

.css-194a1fa-MuiSelect-select-MuiInputBase-input{
    padding-top: 8px !important;
    padding-bottom: 5px !important;
}

/* .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    padding-top: 8.5px !important;
    padding-bottom: 8.5px !important;
} */