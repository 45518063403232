

body {
    background-color: #2d323e;
    margin: 0;
    user-select: none;
    user-zoom: none;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
}

.logo{
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.title {
    font-size: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.msg-error {
    color: #fff;
    font-family: Mulish, sans-serif;
    display: flex;
    flex-direction: column;
}

.logotipo {
    font-weight: bold;
}

.suggestion {
    color: rgb(138, 180, 248);
    text-decoration: none;
}

ul {
    margin-top: 6px;
}

/* Animação do Loading */
.container-loading {
    font-family: Helvetica;
}
.loader {
    height: 20px;
    width: 250px;
    margin: auto;
}
.loader--dot {
    animation-name: loader;
    animation-timing-function: ease-in-out;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-color: black;
    position: absolute;
    border: 2px solid white;
}
.loader--dot:first-child {
    background-color: #ffffff;
    animation-delay: 0.4s;
}
.loader--dot:nth-child(2) {
    background-color: #00aff1;
    animation-delay: 0.3s;
}
.loader--dot:nth-child(3) {
    background-color: #ff2d33;
    animation-delay: 0.2s;
}
.loader--dot:nth-child(4) {
    background-color: #4a4daf;
    animation-delay: 0.1s;
}
@keyframes loader {
    15% {
        transform: translateX(0);
    }
    45% {
        transform: translateX(230px);
    }
    65% {
        transform: translateX(230px);
    }
    95% {
        transform: translateX(0);
    }
}
