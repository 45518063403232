@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

body {
    margin: 0;
}

.my-popup-class {
    font-size: 50px;
}

.swal2-icon-show {
    margin: 1.5em auto 0.6em !important;
}

