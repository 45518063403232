.arrow-pointer {
  width: 250px;
  height: 50px;
  background: #32557f;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0; bottom: 0; width: 0; height: 0;
    border-left: 25px solid #7eb4e2;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
  }

  &:before {
    content: '';
    position: absolute;
    right: -25px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 25px solid #32557f;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
  }
}